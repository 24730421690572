import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71fe3b67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "athletes bg-primary pt-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-white mb-5" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sat_search_input = _resolveComponent("sat-search-input")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_sat_card = _resolveComponent("sat-card")!
  const _component_sat_icon = _resolveComponent("sat-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.t("athletes.name")), 1),
      _createVNode(_component_sat_search_input, {
        "advance-search": "athlete",
        class: "mb-5",
        onSearch: _ctx.handleSearch,
        placeHolder: 
                _ctx.store.state.currentLanguage === 'th'
                    ? 'ค้นหานักกีฬา ชื่อจริง นามสกุล'
                    : 'Find athlete'
            
      }, null, 8, ["onSearch", "placeHolder"]),
      _createVNode(_component_a_card, { bordered: false }, {
        default: _withCtx(() => [
          ((_ctx.fetchingOverview || _ctx.fetchingOverviewByTeam) && _ctx.skeletonLoad)
            ? (_openBlock(), _createBlock(_component_a_skeleton, {
                key: 0,
                class: "py-5",
                active: ""
              }))
            : (_ctx.results.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["row", _ctx.helpers.checkWindowRowCol()])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (data) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: data.id,
                        class: "col mb-4"
                      }, [
                        _createVNode(_component_sat_card, {
                          data: data,
                          type: "athletes"
                        }, null, 8, ["data"])
                      ]))
                    }), 128))
                  ], 2),
                  (_ctx.searching && _ctx.results.length >= _ctx.sizeSearch * _ctx.lastPageSearch)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                        (!_ctx.loadingSearchFilter)
                          ? (_openBlock(), _createBlock(_component_a_button, {
                              key: 0,
                              type: "link",
                              size: "large",
                              onClick: _ctx.onLoadMoreSearchAndFilter
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_sat_icon, {
                                  name: "caretDown",
                                  class: "fs-2"
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : (_openBlock(), _createBlock(_component_LoadingOutlined, {
                              key: 1,
                              class: "fs-2"
                            }))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock(_component_a_empty, {
                  key: 2,
                  class: "py-5"
                }))
        ]),
        _: 1
      })
    ])
  ]))
}